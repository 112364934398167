.accordion-container{
    margin-top: 100px;
    padding-top: 30px;
    height: 100vh;
    background-color: rgba(214, 214, 214, 0.259);
    display: flex;
    justify-content: space-between;
}

.accordion-left > img{
    width: 630px;
    height: 650px;
    margin-left: 30px;
    border: 8px solid black;
}


.accordion-right .one {
    font-size: 50px;
    font-weight: bold;


}
.accordion-right .two {
    font-size: 28px;
    font-weight: 600;
    
    color: rgba(225, 193, 13, 0.567);
    


}
.accordion-right .tree {
    font-size: 15px;
    font-weight: 400;
    padding-top: 10px;
    margin-right: 20px;
    padding-right: 20px;


}

.spans{
    width: 350px;
    margin: 0 auto 10px;
}

.accordion{
    margin-top: 2rem;
    border: none;
}

.accordionItem{
   background: white;
   border: 3.8px solid rgb(7, 6, 6);
   border-radius: 8px;
   overflow: hidden;
   margin: 20px 19px;
}

.accordionItem.expanded{
    box-shadow: 0 0 11px 10px grey;
    border-radius: 6px;
}

.accordionButton{
    background: white;
    padding: 1rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.icon{
    font-size: 20px;
}


.flexCenter{
    display: flex;
    justify-content: center;
    align-items: center;
}

.primaryText{
    font-size: 24px;
    font-weight: 800;
    gap: 5px;
    padding: 10px;
    
}

.secondaryText{
    font-size: 15px;
    font-weight: 500;
    color: rgb(59, 59, 59);
    padding: 10px;
}

















