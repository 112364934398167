.Hero-container{
    background-image: url('https://images.pexels.com/photos/120049/pexels-photo-120049.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y:-100px;
    width: 100vw;
    height: 87vh;
    display: flex;
    align-items: center;
    
    
    
}


.Hero-container h1{
    color: white;
    font-size: 5rem;
    margin-bottom:40px;
    margin-left: 35px;
   
}

.Hero-container h3{
    color: rgba(255, 255, 255, 0.857);
    margin-bottom:30px;
    margin-left: 35px;
    font-size: 2.2rem;
}

.Hero-container h5{
    color: rgb(255, 255, 255);
    margin-bottom:30px;
    margin-left: 35px;
    font-size: 1.8rem;
    letter-spacing: 2px;
}

.Hero-container .best{
    font-size: 2rem;
    color: grey;
}

.Hero-elements{

    margin-top: 55px;
}

.Hero-elements .btn{
    padding: 10px 30px;
    transition: 0.7s ease;
    box-shadow: none;
}

.Hero-elements .btn:hover{
    
    box-shadow: 0 0 100px 5px white;
   transform: translateX(20px);
}


.countupall{
    display: flex;
    margin-left: 35px;
    margin-top: 10px;
}

.countup{
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    margin-top: 39px;
}

.counupNum{
    font-weight: 900;
    color:rgba(255, 255, 255, 0.384);
    font-size: 30px;
}

.HeroT{
    color: rgba(255, 255, 255, 0.383);
    font-size: 25px;
}



@media screen and  (min-width:0px) and (max-width:450px)  {
    .Hero-container{
        background-image: url('https://images.pexels.com/photos/120049/pexels-photo-120049.jpeg');
        background-size:cover;
        background-repeat: no-repeat;
        background-position-x: -150px;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        
    } 
    
    .Hero-container h1{
        color: white;
        font-size: 2.9rem;
        margin-bottom:40px;
        margin-left: 20px;
      
    }

    .Hero-container h3{
        color: rgba(0, 0, 0, 0.882);
        padding-bottom:10px;
        margin-left: 20px;
        font-size: 1.4rem;
        
    }
    
    .Hero-container h5{
        color: rgb(10, 8, 8);
        margin-bottom:30px;
        margin-left: 15px;
        font-size: 1.4rem;
        letter-spacing: 2px;
    }

    .Hero-container .best{
        font-size: 1.6rem;
        color: grey;
    }
    
    .countupall{
        display: flex;
        margin-left: 10px;
        margin-bottom: 20px;
    }

    .countup{
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        margin-top: 19px;
    }
    
    .counupNum{
        font-weight: 900;
        color:rgb(255, 0, 0);
        font-size: 23px;
    }
    
    .HeroT{
        color: rgb(255, 10, 10);
        font-size: 22px;
    }
    
    .Hero-elements .btn{
        padding: 10px 30px;
        transition: 0.7s ease;
        box-shadow: none;
        display: block;
    }
    
    .Hero-elements .btn:hover{
        
        box-shadow: 0 0 100px 5px white;
       transform: translateX(20px);
    }
    
}

@media screen and (min-width:450px) and (max-width:767px) {
   .Hero-container{
        background-image: url('https://images.pexels.com/photos/120049/pexels-photo-120049.jpeg');
        background-size:cover;
        background-repeat: no-repeat;
        background-position-x: -350px;
        height: 100vh;
   }
    
   
    
    .Hero-container h1{
        color: white;
        font-size: 3.7rem;
        margin-bottom:70px;
        margin-left: 25px;
    
    }

    .Hero-container h3{
        color: rgb(207, 207, 207);
        margin-bottom:10px;
        margin-left: 25px;
        font-size: 1.6rem;
    }

    .Hero-container h5{
        color: rgb(255, 255, 255);
        margin-bottom:60px;
        margin-left: 25px;
        font-size: 1.4rem;
        letter-spacing: 2px;
    }

    .Hero-container .best{
        font-size: 1.6rem;
        color: grey;
    }

    .Hero-elements .btn{
        padding: 10px 30px;
        transition: 0.7s ease;
        box-shadow: none;
        display: block;
    }

    .Hero-elements .btn:hover{
        
        box-shadow: 0 0 100px 5px white;
    transform: translateX(20px);
    }

        
    .countupall{
        display: flex;
        margin-left: 25px;
        margin-top: 10px;
    }

    .countup{
        display: flex;
        flex-direction: column;
        margin-left: 25px;
        margin-top: 29px;
    }

    .counupNum{
        font-weight: 900;
        color:rgb(255, 255, 255);
        font-size: 20px;
    }

    .HeroT{
        color: rgba(255, 255, 255, 0.794);
        font-size: 18px;
    }

}

@media screen and (min-width:767px) and (max-width:1200px) {
    .Hero-container{
        background-image: url('https://images.pexels.com/photos/120049/pexels-photo-120049.jpeg');
        background-size:cover;
        background-repeat: no-repeat;
        background-position-y: 0px;
        height: 60vh;
        width: 100vw;
   }

   .countupall{
    display: flex;
    margin-left: 25px;
    margin-top: 0px;
    }

    .HeroT{
        color: rgba(255, 255, 255, 0.794);
        font-size: 15px;
        font-weight: 900;
    }

    .Hero-elements .btn{
        padding: 10px 30px;
        transition: 0.7s ease;
        box-shadow: none;
        display: block;
    }

    .Hero-elements .btn:hover{
        
        box-shadow: 0 0 100px 5px white;
    transform: translateX(20px);
    }

}





  


