.contact-container{
    width: 100vw;
    height: 85vh;
    background-color: #000000;
   
    
}

.ContactUs{
    display: flex;
    justify-content: center;
    
}

.ContactUs h1{
    font-size: 51px;
    margin-bottom: 20px;
    color: white;

    
}



.ForBr{
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid black;
    width: 40%;
    margin: auto;
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: rgba(255, 255, 255, 0.934);
    border-radius: 17px;
    
}

.fullName{
    margin-bottom: 20px;
    
}

.fullName label{
    font-size: 18px;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    
}

.fullName input{
    margin-top: 2px;
    padding: 8px;
    width: 500px;
    border: 3px solid royalblue;
    border-radius: 5px;

}


input::placeholder{
    font-size: 16px;
    background-color: white;
    color: rgba(0, 0, 0, 0.577);
    

}








.Num{
    margin-bottom: 20px;
    
}

.Num label{
    font-size: 18px;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    
}

.Num input{
    margin-top: 2px;
    padding: 8px;
    width: 500px;
    border: 3px solid royalblue;
    border-radius: 5px;

}

.Email{
    margin-bottom: 20px;
    
}

.Email label{
    font-size: 18px;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    
}

.Email input{
    margin-top: 2px;
    padding: 8px;
    width: 500px;
    border: 3px solid royalblue;
    border-radius: 5px;

}

.Message{
    margin-bottom: 20px;
    
}

.Message label{
    font-size: 18px;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    
}

.Message textarea{
    margin-top: 2px;
    padding: 5px;
    width: 500px;
    border: 3px solid royalblue;
    border-radius: 5px;
}

.SubmitBtn{
    margin-left: 390px;
}
.SubmitBtn button{
    padding: 7px 25px;
    font-size: 16px;
    font-weight: 800;
    background-color: royalblue;
    color: white;
    border: none;
    border-radius: 5px;
    transition: 0.5s ease;
}

.SubmitBtn button:hover{
    background-color: black;
    color:rgb(255, 255, 255);
    box-shadow: 0 0 15px 8px rgb(0, 0, 0);
    transform: translate(10px);
}





















