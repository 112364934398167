.Product-container{
    margin-top: 250px;
    margin-left: 10px;
    margin-right: 0;
    margin-bottom: 20px;
    position: relative;
}

.Product-text {
    margin-left: 25px;
}

.Product-text h1 {
    font-size: 50px;
    color:rgba(5, 4, 2, 0.861);
    font-weight: bold;
    
    
}
.Product-text h4 {
    font-size: 28px;
    color:rgba(5, 4, 2, 0.646);
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.flexColStart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }




.r-card{
    gap: 1.2rem;
    padding: 1rem;
    border-radius: 10px;
    max-width: max-content;
    margin: auto;
    transition: all 300ms ease;
    
    
}
.r-card:hover{
    box-shadow: rgba(0, 0, 0, 0.2) 0px 60px 40px -17px;
}

.r-card>img{
    width: 100%;
    max-width: 15rem;
}

.proBtn{
    padding: 5px 7px;
    color: whitesmoke;
    background-color: black;
    border-radius: 3px;
    transition: 0.5s ease;
    
}

.proBtn:hover{
    color: black;
    background-color: grey;
}

.swiper-wrapper{
    align-items: flex-end;
    
}

#swPr{
    overflow: visible;
}

.r-buttons{
    position:absolute;
    gap: 1rem;
    top:-50px ;
    right: 10px;
    
    
} 

.r-buttons button{
    font-size: 1.2rem;
    padding: 0.2rem 0.8rem;
    color: rgb(239, 239, 240);
    border: none;
    border-radius: 5px;
    background-color: rgb(0, 0, 0);
    cursor: pointer;
}

.r-buttons>:nth-child(1){
    background-color: rgba(2, 2, 2, 0.452);
    margin-right: 5px;
}

.r-buttons>:nth-child(2){
    box-shadow: 0 0 5px 1px rgba(198, 194, 194, 0.772);
}



@media screen and (min-width:0px) and (max-width:450px){
    .Product-container{
        margin-top: 150px;
        margin-left: 10px;
        margin-right: 0;
        margin-bottom: 40px;
        position: relative;
    }

    .Product-text {
    margin-left: 0px;
    }

    
    .Product-text h1 {
        font-size: 25px;
        color:rgba(5, 4, 2, 0.861);
        font-weight: bold;
        
        
    }
    .Product-text h4 {
        font-size: 15px;
        color:rgba(5, 4, 2, 0.646);
        font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
        padding-bottom: 25px;
    }

    .flexColStart {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    
    
}
@media screen and (min-width:450px) and (max-width:767px){
    
}
@media screen and (min-width:767px) and (max-width:1200px){
    
}



