*{
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
    text-decoration: none;
    list-style: none;
    margin: 0;
    padding: 0;
}
.container{
    background-color: rgba(0, 0, 0, 0.943);
    width:100vw;
    height: 13vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-image > img{
    width: 100px;
    height: 100px;
    margin: 0px 100px;
    transition: 0.5s ease;
}
.header-image > img:hover{
    transform: rotate(360deg);
}

.elements {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    
}
#active{
    color:white;
}

.elements a{
    color: rgb(76, 74, 74);
    padding: 10px 17px;
    transition: 0.2s;
}

.elements a:hover{
    border-bottom: 4px solid white;
    color:white;
}



.btn{
    margin-left: 100px;
    margin-right: 25px;
    padding: 10px;
    font-weight: 900;
    font-size: 1rem;
    border: none;
    border-radius: 3px;
    background-color: white;
    color: #000;
    transition: 0.5s ease;
    cursor: pointer;
}

.btn:hover {
    transform: scale(1.1);
    background-color: #5e5555;
    color: white;
}



.header-elements{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.toggle-btn{
    display: none;
}







@media screen and (max-width:480px){ 
    
    .container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: auto;
        padding-bottom: 10px;
    }
    
    .header-image > img{
        width: 65px;
        height: auto;
        margin-top: 30px;
        padding-right: 16px;
    }

    .elements{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        font-size: 10px;
        font-weight: 600;
    }

    .elements a{
        color: rgb(76, 74, 74);
        padding: 6px 14px;
        transition: 0.2s;
        font-size: 13px;
    }

    .elements a:hover{
        border-bottom: 2px solid white;
        color:white;
    }

    .header-image > img:hover{
        transform: none;
    }

    .toggle-btn {
        display: flex;  /* Hide toggle button by default */
        background-color: transparent;
        border: none;
        color: white;
        font-size: 16px;
        cursor: pointer;
        position: absolute;
        top: 55px;
        right: 0;
        margin-right: 20px; /* Adjust margin as needed */
    }

    #open{
        display: flex;
        flex-direction: column;
    }

    #close{
        display: none;
    }

}


@media screen and (max-width: 768px){
    
    .container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: auto;
        padding-bottom: 10px;
    }
    
    .header-image > img{
        width: 100px;
        height: auto;
        margin-top: 30px;
        padding-right: 16px;
    }

    .elements{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        font-size: 17px;
        font-weight: 800;
    }

    .elements a{
        color: rgb(76, 74, 74);
        padding: 6px 14px;
        transition: 0.2s;
    }

    .elements a:hover{
        border-bottom: 2px solid white;
        color:white;
    }

    .header-image > img:hover{
        transform: none;
    }

    .btn{
        display: none;
    }
    
    .toggle-btn {
        display: flex;  /* Hide toggle button by default */
        background-color: transparent;
        border: none;
        color: white;
        font-size: 16px;
        cursor: pointer;
        position: absolute;
        top: 55px;
        right: 0;
        margin-right: 20px; /* Adjust margin as needed */
    }

    #open{
        display: flex;
        flex-direction: column;
    }

    #close{
        display: none;
    }


}





@media screen and (max-width: 1200px) {
    
    .btn{
        display: none;
    }

    .elements {
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-size: 18px;
        font-weight: bold;
        margin-right: 30px;
    }
}





























