*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  
}

body{
  overflow-x: hidden;
}
::-webkit-scrollbar{
  width: 15px;
}


::-webkit-scrollbar-track{
  background-color:rgb(11, 11, 11);
}

::-webkit-scrollbar-thumb{
  background-color:  rgb(221, 215, 215);
  border-radius: 10px;
}




