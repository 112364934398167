.Footer-container{
    margin-top: 50px;
    width: 100vw;
    
    display: flex;
    justify-content: space-between;
    background-color: black;
}

.footer-left img{
    width: 25%;
}

.footer-left h3{
    color: white;
    margin-left: 25px;
}


.footer-right{
    margin-right: 80px;
}

.input-btn{
    display: flex;

}

.input-btn input{
    padding: 5px 15px;
    width: 280px;
    margin-top: 20px;
    margin-right: 30px;
    border-radius: 5px;
    border: 3px solid royalblue;
}

.input-btn input::placeholder{
    color: black;
   
}

.input-btn button{
    color: black;
    margin-top: 20px;
    padding: 0 13px;
    border-radius: 3px;
    font-size: 15px;
    font-weight: 600;
    background-color: rgba(65, 105, 225, 0.753);
    border: none;
    
}



.header-footer{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.header-footer a{
    font-size: 18px;
    color: white;
    transition: 0.7s ease;
}

.header-footer a:hover{
    transform: translateX(9px);
}

.arrow{
    display: flex;
    flex-direction: row;
}


.arw{
    color: white;
    font-size: 22px;
    padding-bottom: 5px;
    padding-right: 5px;
    transition: 500ms ease;
}


.arw:hover{
    transform: rotate(360deg);
}