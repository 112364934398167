
.Sponsor-container{
    width: 100vw;
    height: 30vh;
    margin-top: 150px;
    margin-bottom: 80px;
}

.Sponsor-elements{
    background-color:rgba(0, 0, 0, 0.048);
    box-shadow: 1px 1px 20px 10px rgba(0, 0, 0, 0.048);
}

.Sponsor-elements>img{
    max-width: 300px;
    min-height: 130px; 
    padding-left: 23px;
}


.Sponsor-name >h1{
    font-family: "Oswald", sans-serif;
    font-size: 3rem;
    margin-right: 10px;
    margin-top: 25px;
    margin-bottom: 10px;
    
    
}

.Sponsor-name >p{
    font-size: 1.5rem;
    font-family: "Lato", sans-serif;
    font-weight: 600;
    margin-left: 37px;
}
.center-spo{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}



@media screen and (min-width:0px) and (max-width:480px) {
    .Sponsor-container{
        width: 100vw;
        height: 90vh;
        margin-top: 60px;
        margin-bottom: 80px;
    }

    .Sponsor-elements{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .Sponsor-elements>img{
        max-width: 170px;
        min-height: 130px; 
        padding-bottom: 8px;
        margin-left: 1.4rem;
        
    }

    .Sponsor-name >p{
        font-size: 1.1rem;
        font-family: "Lato", sans-serif;
        font-weight: 600;
        margin-left: 25px;
        
    }

}

@media screen and (min-width:480px) and (max-width:768px){
    .Sponsor-container{
        width: 100vw;
        height: 90vh;
        margin-top: 60px;
        margin-bottom: 0;
    }

    .Sponsor-elements{
        display: flex;
        flex-wrap:wrap;
        justify-content: center;
        flex-direction: row;
        align-items: center;
    }

    .Sponsor-elements>img{
        max-width: 170px;
        min-height: 130px; 
        padding-bottom: 8px;
        margin-left: 1.4rem;
        
    }

    .Sponsor-name >p{
        font-size: 1.1rem;
        font-family: "Lato", sans-serif;
        font-weight: 600;
        margin-left: 25px;
        
    }
}

@media screen and (min-width:768px) and (max-width:1200px){

}


